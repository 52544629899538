<template>
    <supportLayout>
        <div class="p-changelog full_page">

            <div class="container">
                <div class="coming_soon">
                    <h3>In Development</h3>
                </div>
            </div>
        </div>
    </supportLayout>
</template>

<script>
import supportLayout from "@/layouts/supportLayout.vue";

export default {
    name: 'changelog',
    components: {
        supportLayout
    }
}
</script>
